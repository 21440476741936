import logo from './logo.svg';
import './App.css';
import DeviceDetector from "device-detector-js";

function App() {
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);
    const objectStore = document.getElementById("deviceInfor");
    if(objectStore) {
      objectStore.value = JSON.stringify(device);
    }
    console.log(JSON.stringify(device));
  return (
        <></>
  );
}

export default App;
